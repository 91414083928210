/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';
import Header from './header';
import './layout.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={
          {
            // margin: `0 auto`,
            // maxWidth: 960,
            // padding: `0 1.0875rem 1.45rem`,
          }
        }
      >
        <main>
          {children}
          <div className="icon-bar">
            <a
              href="https://www.facebook.com/travinsidercom/"
              className="facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/trav.insider/"
              className="instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCkevfTLh3MCLQbCyUEv-Z5A"
              className="youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube"></i>
            </a>
          </div>
        </main>
        {/* <footer>© {new Date().getFullYear()} Travinsider</footer> */}
        <footer id="main-footer" className="py-1">
          <div className="container footer-container">
            {/* <div>
				<div class="logo">
					<img src="img/logoblue.png" alt="TravInsider" class="logo">
				</div>
				<p class="footer-logo">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>	
			</div>
			<div>
				<h3>Email Newsletter</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
				<form>
					<input type="email" placeholder="Enter Email">
					<input type="submit" value="Subscribe" class="btn btn-primary">
				</form>
			</div>
			<div>
				<h3>Site Links</h3>
				<ul class="list">
					<li><a href="#">Help & Support</a></li>
					<li><a href="#">Privacy Policy</a></li>
					<li><a href="#">About Us</a></li>
					<li><a href="#">Contact</a></li>
				</ul>	
			</div>
			<div>
				<h3>Join Our Club</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
				<a href="#" class="btn btn-primary">Join Now</a>
			</div>  */}
            <div>
              <p
                css={css`
                  text-align: center;
                `}
              >
                &copy; {new Date().getFullYear()} Travinsider. All rights
                reserved
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
