import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ThBannerImage from './th-banner-img';
import EnBannerImage from './en-banner-img';

// const Header = ({ siteTitle }) => (
//   <header
//     style={{
//       background: `#333`,
//       // marginBottom: `1.45rem`,
//     }}
//   >
//     <div
//       style={{
//         margin: `0 auto`,
//         maxWidth: 960,
//         padding: `1.45rem 1.0875rem`,
//       }}
//     >
//       <h1 style={{ margin: 0 }}>
//         <Link
//           to="/"
//           style={{
//             color: `white`,
//             textDecoration: `none`,
//           }}
//         >
//           {siteTitle}
//         </Link>
//       </h1>
//     </div>
//   </header>
// );
const Header = ({ siteTitle }) => (
  <nav id="main-nav">
    <div className="container">
      <Link to="/" as="a">
        {process.env.GATSBY_LANGUAGE === 'th' ? (
          <ThBannerImage />
        ) : (
          <EnBannerImage />
        )}
      </Link>
      <div className="social">
        {/* <a href="https://www.facebook.com/travinsidercom/">
          <i
            style={{ color: '#3b5998' }}
            className="fab fa-facebook-square fa-2x"
          ></i>
        </a>
        &nbsp;
        <a href="https://www.youtube.com/channel/UCkevfTLh3MCLQbCyUEv-Z5A">
          <i style={{ color: '#c4302b' }} className="fab fa-youtube fa-2x"></i>
        </a> */}
        {/* &nbsp;
        <a href="http://instragram.com" target="_blank">
          <i className="fab fa-instagram fa-2x "></i>
        </a>
        &nbsp;
        <a href="#" target="_blank">
          <i className="fas fa-envelope fa-2x"></i>
        </a> */}
      </div>
      <ul>
        <li>
          <Link to="/">
            {process.env.GATSBY_LANGUAGE === 'th' ? 'หน้าแรก' : 'Home'}
          </Link>
        </li>
        <li>
          <Link to="/blog">
            {process.env.GATSBY_LANGUAGE === 'th' ? 'บล็อก' : 'Blogs'}
          </Link>
        </li>
        <li>
          <Link to="/about">
            {process.env.GATSBY_LANGUAGE === 'th' ? 'เกี่ยวกับ' : 'About'}
          </Link>
        </li>
      </ul>
    </div>
  </nav>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
